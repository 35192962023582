import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { GenericService } from '../../generic.service';
import { RegistrationService } from '../../registration.service';
import { SupportDataService } from '../../support-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { YardTaskService } from 'src/app/account/shared/yard-task.service';
import { DriversService } from 'src/app/drivers/shared/drivers.service';
import { of, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { CheckinService } from 'src/app/checkin/shared/checkin.service';

@Component({
  selector: 'app-add-dialog-tasks',
  templateUrl: './add-dialog-tasks.component.html',
  styleUrls: ['./add-dialog-tasks.component.scss']
})
export class AddDialogTasksComponent implements OnInit {

  task_types: any = [];

  columnsSchema: any[] = [];
  transformData: any = {};
  transformMethods: any = {}
  displayedColumns: string[] = [];
  dataSource: any[] = [];
  truckerDrivers: any[] = [];
  task: any = {};
  valetsDrivers: [] = [];
  displayedDrivers: any[] = [];

  form = this.formBuilder.group({
    name: [{ value: "", disabled: true }, Validators.required],
    observation: [""],
    taskModel: [""],
    search: [""],
    valetsOnly: [false],
    driver: [""],
  });

  display_message = false;
  message = '';
  user: any;
  showTable = false;

  constructor(
    private genericService: GenericService,
    private supportDataService: SupportDataService,
    private checkinService: CheckinService,
    private yardTaskService: YardTaskService,
    public formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<any>,
    private driversService: DriversService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getTaskTypesList();
    this.getDrivers();
    this.observeValetsOnly();

    if (this.data?.item?.id) {
      this.getData();
    }

    this.form.get('search')?.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          if (!searchTerm) {
            return of(this.form.get('valetsOnly')?.value ? this.valetsDrivers : this.truckerDrivers);
          }

          const isValet = this.form.get('valetsOnly')?.value;
          return isValet
            ? this.driversService.getValetsDrivers({ search: searchTerm })
            : this.driversService.getDrivers({ search: searchTerm });
        })
      )
      .subscribe((response: any) => {
        this.displayedDrivers = response || [];
      });


      this.form.get('taskModel')?.valueChanges.subscribe((value) => {
        if (value === 'Outro') {
          this.form.get('name')?.enable();
        } else {
          this.form.get('name')?.disable();
        
        }
      });

  }

  getData() {
    const options = {
      search: false,
      paginate: false,
      pageSize: 0,
      page: 1,
      orderBy: '',
      sortedBy: '',
    };

    this.yardTaskService.get(this.data?.item?.id, options).subscribe((r: any) => {
      this.task = r.resource;
      if (r.resource) {
        this.form.patchValue({
          name: r.resource?.name,
          observation: r.resource?.observation,
          driver: r.resource?.driver,
          valetsOnly: r.resource?.valetsOnly,
          taskModel: r.resource?.taskModel
        });

        if (r.resource.taskModel === 'Outro') {
          this.form.get('name')?.enable();
        } else {
          this.form.get('name')?.disable();
        }
      }
    });
  }

  submit() {
    const submitData = { ...this.form.value }

    delete submitData.search;
    if(!submitData.taskModel){
      delete submitData.taskModel;
    }

    const updatedResource = Object.assign({}, this.task, submitData);
    const data: any = {
      resource: updatedResource
    }

    if (this.data.hasOwnProperty('origin')) {
      if (this.data.origin === 'task_id') {
        data.id = this.data?.item?.id
      } else {
        const origin = this.data.origin as keyof typeof data;
        data[origin] = this.data.id;
      }
    }

    if (data.id) {
      this.yardTaskService.update(data).subscribe((r: any) => {
        this.dialogRef.close();
      });
    } else {
      this.yardTaskService.create(data).subscribe((r: any) => {

        // validação para checar se é uma tarefa criada apartir do modulo de jornadas
        if (this.data.item.resource) {
          const checkinData = { ...this.data.item.resource };
          const checkinResource = Object.assign({}, { task_id: r.task_id, checkin_id: this.data.item.id }, checkinData);

          //atualiza o resource da jornada, atribuindo o task_id
          this.checkinService.updateResource(checkinResource).subscribe(() => {
            this.dialogRef.close();
          });;
        } else {
          this.dialogRef.close();
        }
      });
    }

  }

  cancel() {
    this.dialogRef.close();
  }

  observeValetsOnly() {
    this.form.get('valetsOnly')?.valueChanges.subscribe((isValet: boolean) => {
      if (isValet) {
        this.getValetsDrivers();
      } else {
        this.getDrivers();
      }
    });
  }


  getValetsDrivers() {
    this.driversService.getValetsDrivers()
      .pipe(
        tap((response: any) => {
          this.displayedDrivers = response;
          this.valetsDrivers = response;
        })
      )
      .subscribe();
  }

  getDrivers() {
    this.driversService.getDrivers()
      .pipe(
        tap((response: any) => {
          this.truckerDrivers = response;
          this.displayedDrivers = response;
        })
      )
      .subscribe();
  }


  getTaskTypesList() {
    this.supportDataService.generic({ name: 'task_types' }).subscribe((r: any) => {
      this.task_types = r.data.resource;
    });
  }
}

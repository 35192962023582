<div class="tkg-title-group">
  <p class="tkg-title">Duração de Agendamento por Faixa de Peso ou Volume</p>
  <button mat-button mat-stroked-button color="primary" class="tkg-new-btn" (click)="addEdit()">
    {{ "Business_model.new" | translate: { Default: "Novo" } }}
  </button>
</div>
<div class="tkg-description">
  Defina a duração do agendamento por faixa de peso.
</div>
<div class="tkg-table-container">
  <table class="tkg-table">
    <thead>
      <tr>
        <th *ngIf="!hiddenElements?.department === true">
          {{ "Business_model.department" | translate: { Default: "Departamento" }
          }}<br />
        </th>
        <th>
          {{ "Business_model.dock" | translate: { Default: "Doca" }
          }}<br />
        </th>
        <th>
          {{ "Business_model.operation" | translate: { Default: "Operação" }
          }}<br />
        </th>
        <th>
          {{
          "Business_model.weight_range_id"
          | translate: { Default: "Faixa de Peso" }
          }}<br />
        </th>
        <th>
          {{ "Business_model.start" | translate: { Default: "Duração" } }}<br />
        </th>
        <th style="width: 80px; text-align: center">
          {{ "Business_model.actions" | translate: { Default: "Ações" } }}<br />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of windows">
        <td *ngIf="!hiddenElements?.department === true">
          {{ item.department }}
        </td>
        <td>{{ item.dock_name }}</td>
        <td>{{ item.operation }}</td>
        <td>{{ item.weight_range_name }}</td>
        <td>{{ item.duration }}</td>
        <td>
          <button mat-icon-button color="primary" (click)="addEdit(item)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="delete(item.id)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </tr>
      <tr *ngIf="windows.length === 0">
        <td colspan="100%">
          <p class="no-record">
            {{
            "Messages.no_register"
            | translate: { Default: "Nenhum registro encontrado!" }
            }}
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
